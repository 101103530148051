import React from "react"
import { BackButton } from '../components/Icons'

const Page = () => {
    
    const bodyStyle = {margin: 'auto', width: '75%', textAlign: 'left', overflowY: 'auto', maxHeight: '70%'}

    return(
        <div className="base-layout">
            <div className="title">
                data policy
            </div>
            <div className="medium-text" style = {bodyStyle} >
                This policy was last updated on 4 June 2022.
                <br/><br/>
                This website is run by Diarmid Mackenzie, who can be contacted through the <a href='https://discord.gg/wHrU2afRFn'>decision.ninja discord server</a>
                <br/><br/>
                At this time, no data is collected about usage of the website.
                <br/><br/>
                In future we do intend collect (in a privacy-preserving manner) anonymous data about how the website is being used, such as:
                <ul>
                    <li>Navigation between pages</li>
                    <li>Which needs are selected as relevant to a decision</li>
                    <li>The scores awarded to individual needs, and how these relate to final decisions taken</li>
                </ul>
                The motivation for collecting this data is to improve the quality of support for decision-making that this website can provide.
                <br/><br/>
                When we make these changes, this data policy will be updated to reflect exactly what data we collect and what we do with that data.
                <br/><br/>
                We will never collect data concerning individual users' identities, their descriptions of the decisions that they are taking, or the options that they are choosing between.
            </div>
            <BackButton
                text="back"/>
        </div>
    )
}

export default Page